<template>
  <div class="mt-4">
    <b-row>
      <b-col md="12" class="col-12 mb-3">
        <div>
          <b-form-checkbox v-model="selecionarTodos">
            {{ $t('GERAL.SELECIONAR_TODOS') }}
          </b-form-checkbox>

          <!-- Mensagem de aviso ao user: -->
          <strong v-if="selecionarTodos">{{
            $t('GERAL.SELECIONAR_TODOS_AVISO')
          }}</strong>
        </div>
      </b-col>
    </b-row>
    <b-table
      class="tabela-contas-receber"
      :items="table.items"
      :fields="table.fields"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      stacked="md"
      show-empty
      responsive
      striped
      hover
    >
      <template #cell(liberadoPortal)="item">
        <b-badge
          :variant="item.item.liberadoPortal == true ? 'info' : 'warning'"
        >
          {{
            item.item.liberadoPortal == true
              ? $t('FINANCEIRO_CONTAS_RECEBER.LIBERADO_PORTAL_ALUNO')
              : $t('FINANCEIRO_CONTAS_RECEBER.NAO_LIBERADO_PORTAL_ALUNO')
          }}
        </b-badge>
      </template>
      <template #cell(marcar)="item">
        <b-form-checkbox
          v-model="item.item.checked"
          :value="true"
          :unchecked-value="false"
          @change="marcaDesmarcaUm(item.item)"
        />
      </template>
      <template #cell(nomeAluno)="item">
        {{ item.item.codigoMatricula }} - {{ item.item.nomeAluno }}
      </template>
      <template #cell(statusPagamentoDescricao)="item">
        <div
          class="estilo-status-tabela"
          v-if="item.item.statusPagamentoDescricao == 'Cancelado'"
          style="background: red"
        >
          {{ item.item.statusPagamentoDescricao }}
        </div>
        <div
          class="estilo-status-tabela"
          v-if="item.item.statusPagamentoDescricao == 'Liquidado'"
          style="background: green"
        >
          {{ item.item.statusPagamentoDescricao }}
        </div>
        <div
          class="estilo-status-tabela"
          v-if="item.item.statusPagamentoDescricao == 'Liquidado Parcialmente'"
          style="background: #7ccd7c"
        >
          {{ item.item.statusPagamentoDescricao }}
        </div>
        <div
          class="estilo-status-tabela"
          v-if="item.item.statusPagamentoDescricao == 'Estornado'"
          style="background: #3a443a"
        >
          {{ item.item.statusPagamentoDescricao }}
        </div>
        <div
          class="estilo-status-tabela"
          v-if="item.item.statusPagamentoDescricao == 'Aberto'"
          style="background: #fbcd00"
        >
          {{ item.item.statusPagamentoDescricao }}
        </div>
        <div
          v-if="
            item.item.statusPagamentoDescricao !== 'Aberto' &&
            item.item.statusPagamentoDescricao !== 'Liquidado' &&
            item.item.statusPagamentoDescricao !== 'Liquidado Parcialmente' &&
            item.item.statusPagamentoDescricao !== 'Estornado' &&
            item.item.statusPagamentoDescricao !== 'Cancelado'
          "
        >
          {{ item.item.statusPagamentoDescricao }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

export default {
  props: {
    items: { type: Array, default: Array },
    filtrarPor: { type: [Object, Array], default: Object },
  },
  data() {
    return {
      table: {
        items: [],
        fields: [
          {
            key: 'marcar',
            label: '',
          },
          {
            key: 'nomeAluno',
            label: this.$t('FINANCEIRO_CONTAS_RECEBER.NOME_ALUNO'),
            sortable: true,
          },
          {
            key: 'valor',
            label: this.$t('FINANCEIRO_CONTAS_RECEBER.VALOR'),
            formatter: (value) => {
              return value ? helpers.formatacaoMoedaBRL(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'juros',
            label: this.$t('FINANCEIRO_CONTAS_RECEBER.JUROS'),
            formatter: (value) => {
              return value ? helpers.formatacaoMoedaBRL(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'desconto',
            label: this.$t('FINANCEIRO_CONTAS_RECEBER.DESCONTO'),
            formatter: (value) => {
              return value ? helpers.formatacaoMoedaBRL(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'total',
            label: this.$t('FINANCEIRO_CONTAS_RECEBER.TOTAL'),
            formatter: (value) => {
              return value ? helpers.formatacaoMoedaBRL(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'dataLancamento',
            label: this.$t('FINANCEIRO_CONTAS_RECEBER.DATA_LANCAMENTO'),
            formatter: (value) => {
              return value ? helpers.formatarDataBr(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'dataVencimento',
            label: this.$t('FINANCEIRO_CONTAS_RECEBER.DATA_VENCIMENTO'),
            formatter: (value) => {
              return value ? helpers.formatarDataBr(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'dataPagamento',
            label: this.$t('FINANCEIRO_CONTAS_RECEBER.DATA_PAGAMENTO'),
            formatter: (value) => {
              return value ? helpers.formatarDataBr(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'numeroRecibo',
            label: this.$t('FINANCEIRO.RECIBO'),
            sortable: true,
          },
          {
            key: 'statusPagamentoDescricao',
            label: this.$t('FINANCEIRO_CONTAS_RECEBER.STATUS_PAGAMENTO'),
            sortable: true,
          },
          {
            key: 'liberadoPortal',
            label: this.$t('FINANCEIRO_CONTAS_RECEBER.ACESSO_PORTAL'),
            sortable: true,
          },
          {
            key: 'historicoDescricao',
            label: this.$t('FINANCEIRO_CONTAS_RECEBER.HISTORICO'),
            sortable: true,
          },
          {
            key: 'contaDescricaoComCodigo',
            label: this.$t('FINANCEIRO_CONTAS_RECEBER.CONTA'),
            sortable: true,
          },
          {
            key: 'codigo',
            label: this.$t('FINANCEIRO_CONTAS_RECEBER.DOCUMENTO'),
            sortable: true,
          },
        ],
      },
      itensSelecionados: [],
      listaTemporaria: [],
      selecionarTodos: false,
    };
  },
  methods: {
    limparItensArmazenados() {
      this.itensSelecionados = [];
      this.listaTemporaria = [];
    },
    // FUNÇÃO PARA MARCAR/DESMARCAR TODOS:
    marcarDesmarcarTodos() {
      // resetando a lista:
      this.listaTemporaria = [];

      // alterando todos os checked de acordo com o dado do pai:
      this.items.forEach((element) => {
        element.checked = this.selecionarTodos;
        this.listaTemporaria.push(element);
      });

      // atualizando lista de itens selecionados e emitindo ao pai:
      this.itensSelecionados = this.selecionarTodos ? this.listaTemporaria : [];
      this.emitirDados();

      // retornando a lista temporária:
      return (this.table.items = this.listaTemporaria);
    },
    // FUNÇÕES PARA MARCAR/DESMARCAR UM ITEM:
    marcaDesmarcaUm(item) {
      item.checked ? this.adicionaLista(item) : this.removeLista(item);
      this.emitirDados();
    },

    adicionaLista(item) {
      this.listaTemporaria = this.listaTemporaria.filter(
        (element) => element.id != item.id
      );
      this.listaTemporaria.push(item);
      this.itensSelecionados = this.listaTemporaria;
    },
    removeLista(item) {
      this.listaTemporaria = this.listaTemporaria.filter(
        (element) => element.id !== item.id
      );
      this.itensSelecionados = this.listaTemporaria;
    },
    // FUNÇÃO PARA ENVIAR OS DADOS AO PAI:
    emitirDados() {
      // Confere se as matrículas são iguais:
      var arr = this.itensSelecionados.map((item) => {
        return item.codigoMatricula;
      });
      const allEqual = arr.every((v) => v === arr[0]);

      this.$emit('itens-selecionados-tabela', this.itensSelecionados, allEqual);
    },
  },
  watch: {
    items() {
      this.itensSelecionados = [];
      this.table.items = this.items;
    },
    selecionarTodos: 'marcarDesmarcarTodos',
    filtrarPor: 'limparItensArmazenados',
  },
};
</script>

<style>
.tabela-contas-receber {
  white-space: nowrap;
  font-size: 14px;
}

.tabela-contas-receber .thead-light th {
  padding: 15px 0.4rem !important;
}

.tabela-contas-receber td {
  padding: 5px 0.4rem !important;
}
</style>
