<template>
  <!--
        EXEMPLO DE USO DO COMPONENTE:
        <div class="col-12">
            <filtro-matricula-nome-contrato @resultado="obterMatriculaNomeContrato" :limpar="filtro.limpar" />
        </div>
    -->

  <b-row>
    <b-col md="6" class="mt-3">
      <input-select-multiple
        ref="matriculaId"
        v-model="selecionado.matriculaId"
        :label="$t('FINANCEIRO.BUSCAR_NOME_CODIGO_MATRICULA')"
        placeholder="GERAL.DIGITE_OU_PROCURE"
        :options="opcoes.matriculas"
      />
    </b-col>

    <b-col md="6" class="mt-3">
      <input-select-multiple
        ref="contratoId"
        v-model="selecionado.contratoId"
        :label="$t('FINANCEIRO.CONTRATOS_DESTA_MATRICULA')"
        :placeholder="placeholderContrato"
        :options="opcoes.contratos"
        :disabled="desabilitar.contrato"
      />
    </b-col>
  </b-row>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import ContratosService from '@/common/services/contratos/contratos.service.js';
import MatriculaService from '@/common/services/matriculas/matriculas.service.js';

// Components:
import { InputSelectMultiple } from '@/components/inputs';

export default {
  props: {
    limpar: { type: Boolean, default: false },
  },
  components: { InputSelectMultiple },
  data() {
    return {
      opcoes: {
        contratos: [],
        matriculas: [],
      },
      selecionado: {},
      desabilitar: {
        contrato: true,
      },
    };
  },
  computed: {
    placeholderContrato() {
      return this.desabilitar.contrato
        ? 'GERAL.SELECIONE_MATRICULA_PARA_HABILITAR'
        : this.opcoes.contratos.length
        ? 'GERAL.DIGITE_OU_PROCURE'
        : 'GERAL.SEM_CONTRATOS_CADASTRADOS';
    },
  },
  watch: {
    limpar: 'limparFiltro',
    'selecionado.matriculaId'() {
      if (this.selecionado.matriculaId.value) {
        this.getContratos();
        this.$emit('resultado', this.selecionado);
      }
    },
    'selecionado.contratoId'() {
      this.$emit('resultado', this.selecionado);
    },
  },
  mounted() {
    this.listarMatriculasSemPaginacao();
  },
  methods: {
    limparFiltro() {
      this.selecionado.matriculaId = '';
      this.selecionado.contratoId = '';
    },
    listarMatriculasSemPaginacao() {
      this.$store.dispatch(START_LOADING);
      MatriculaService.listarSemPaginacao()
        .then(({ data }) => {
          if (data.length) {
            data = data.map((row) => {
              return {
                text: `${row.codigoMatricula} - ${row.nome}`,
                value: row.id,
              };
            });
            this.opcoes.matriculas = data;
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getContratos() {
      this.$store.dispatch(START_LOADING);
      ContratosService.buscarContratoPorMatriculaId(
        this.selecionado.matriculaId.value
      )
        .then(({ data }) => {
          data = data.itens.map((row) => {
            return {
              text: `${row.codigoContrato} - ${row.tipoContratoDescricao} - ${row.grupoTurmaDescricao}`,
              value: row.id,
            };
          });
          this.opcoes.contratos = data;
          this.desabilitar.contrato = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
