<template>
  <b-row>
    <b-col md="4">
      <InputSelectTyping
        ref="matriculaId"
        v-model="selecionados.matriculaId"
        :options="opcoes.matriculas"
        :label="$t('FINANCEIRO.BUSCAR_NOME_CODIGO_MATRICULA')"
        placeholder="GERAL.DIGITE_OU_PROCURE"
        @pesquisa-nome="buscaMatriculasPorNome"
        :loading="loadingMatricula"
      />
    </b-col>
    <b-col md="4">
      <input-select-multiple
        ref="statusPagamentoId"
        v-model="selecionados.statusPagamentoId"
        :label="$t('FINANCEIRO_CONTAS_PAGAR.STATUS')"
        placeholder="FINANCEIRO_CONTAS_PAGAR.STATUS_PLACEHOLDER"
        :options="opcoes.statusPagamento"
        :loading="loadingStatus"
      />
    </b-col>
    <b-col md="4">
      <input-select-multiple
        ref="historicoLancamentoId"
        v-model="selecionados.historicoLancamentoId"
        :label="$t('FINANCEIRO_CONTAS_RECEBER.HISTORICO')"
        placeholder="FINANCEIRO_CONTAS_RECEBER.DIGITE_OU_PROCURE"
        :options="opcoes.historicos"
        :loading="loadingHistorico"
      />
    </b-col>
    <b-col md="5" class="mt-3">
      <input-select-multiple
        ref="contratoId"
        v-model="selecionados.contratoId"
        :label="$t('FINANCEIRO.CONTRATOS_DESTA_MATRICULA')"
        :placeholder="placeholderContrato"
        :options="opcoes.contratos"
        :loading="loadingContrato"
        :disabled="desabilitar.contrato"
      />
    </b-col>

    <b-col md="4" class="mt-3">
      <input-select-multiple
        ref="contaId"
        v-model="selecionados.contaId"
        :label="$t('FINANCEIRO_CONTAS_RECEBER.CONTA')"
        :options="opcoes.contas"
        placeholder="FINANCEIRO_CONTAS_RECEBER.DIGITE_OU_PROCURE"
        :loading="loadingConta"
      />
    </b-col>
    <b-col md="3" class="mt-3">
      <input-text
        ref="codigo"
        v-model="selecionados.codigo"
        type="text"
        label="FINANCEIRO_CONTAS_PAGAR.DOCUMENTO"
        placeholder="FINANCEIRO_CONTAS_PAGAR.DOCUMENTO_PLACEHOLDER"
      />
    </b-col>
    <!-- Filtros avançados -->
    <!-- data de lançamento -->
    <data-lancamento
      v-if="exibirBuscaAvancada"
      class="botao-acao-filtro mt-3"
      :exibir="opcoesAvancadas.dataLancamento"
      :form="selecionados"
    >
      <template #checkbox>
        <input-checkbox
          v-model="opcoesAvancadas.dataLancamento"
          :label="$t('FINANCEIRO_CONTAS_RECEBER.DATA_LANCAMENTO')"
        />
      </template>
    </data-lancamento>

    <!-- data de pagamento -->
    <data-pagamento
      v-if="exibirBuscaAvancada"
      class="botao-acao-filtro"
      :exibir="opcoesAvancadas.dataPagamento"
      :form="selecionados"
    >
      <template #checkbox>
        <input-checkbox
          v-model="opcoesAvancadas.dataPagamento"
          :label="$t('FINANCEIRO_CONTAS_RECEBER.DATA_PAGAMENTO')"
        />
      </template>
    </data-pagamento>

    <!-- data de vencimento -->
    <data-vencimento
      v-if="exibirBuscaAvancada"
      class="botao-acao-filtro"
      :exibir="opcoesAvancadas.dataVencimento"
      :form="selecionados"
    >
      <template #checkbox>
        <input-checkbox
          v-model="opcoesAvancadas.dataVencimento"
          :label="$t('FINANCEIRO_CONTAS_RECEBER.DATA_VENCIMENTO')"
        />
      </template>
    </data-vencimento>

    <b-col md="auto" class="d-md-flex align-items-md-center">
      <input-checkbox
        variant="primary"
        class="botao-acao-filtro my-3 w-100"
        :label="$t('GERAL.BUSCA_AVANCADA')"
        v-model="exibirBuscaAvancada"
      />
    </b-col>

    <b-col md="auto">
      <b-button
        variant="primary"
        class="botao-acao-filtro mb-3 w-100"
        @click="filtrarPor"
      >
        {{ $t('GERAL.FILTRAR') }}
      </b-button>
    </b-col>

    <b-col md="auto">
      <b-button
        variant="secondary"
        class="botao-acao-filtro mb-3 w-100"
        @click="limparFiltro"
      >
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </b-col>

    <!-- ações e relatórios  -->
    <b-col md="auto" v-if="exibirBotoesRelatorios">
      <b-button
        variant="info"
        class="botao-acao-filtro mb-3 w-100"
        @click.prevent="gerarBoleto"
        :disabled="disabled.gerarBoleto"
      >
        {{ $t('FINANCEIRO_CONTAS_RECEBER.GERAR_BOLETO') }}
      </b-button>
    </b-col>
    <b-col md="auto" v-if="exibirBotoesRelatorios">
      <b-button
        variant="info"
        class="botao-acao-filtro mb-3 w-100"
        @click.prevent="baixarTitulo"
        :disabled="BtnBaixarTitulo"
      >
        {{ $t('FINANCEIRO_CONTAS_RECEBER.BAIXAR_TITULO') }}
      </b-button>
    </b-col>
    <b-col md="auto" v-if="exibirBotoesRelatorios">
      <b-button
        variant="info"
        class="botao-acao-filtro mb-3 w-100"
        @click.prevent="estornar"
        :disabled="disabled.fazerEstorno"
      >
        {{ $t('FINANCEIRO_CONTAS_RECEBER.ESTORNAR_TITULO') }}
      </b-button>
    </b-col>
    <b-col md="auto" v-if="exibirBotoesRelatorios">
      <b-button
        variant="info"
        class="botao-acao-filtro mb-3 w-100"
        @click="cancelarTitulo"
        :disabled="disabled.cancelar"
      >
        {{ $t('FINANCEIRO.CANCELAR_TITULO') }}
      </b-button>
    </b-col>
    <b-col md="auto" v-if="exibirBotoesRelatorios">
      <b-button
        variant="info"
        class="botao-acao-filtro mb-3 w-100"
        @click.prevent="portalLiberar"
        :disabled="disabled.liberarPortal"
        v-b-tooltip.hover
        :title="$t('FINANCEIRO.BTN_LIBERAR_PORTAL_EXPLICACAO')"
      >
        {{ $t('FINANCEIRO_CONTAS_RECEBER.LIBERAR_PORTAL') }}
      </b-button>
    </b-col>
    <b-col md="auto" v-if="exibirBotoesRelatorios">
      <b-button
        variant="info"
        class="botao-acao-filtro mb-3 w-100"
        @click.prevent="portalBloquear"
        :disabled="disabled.bloquearPortal"
        v-b-tooltip.hover
        :title="$t('FINANCEIRO.BTN_BLOQUEAR_PORTAL_EXPLICACAO')"
      >
        {{ $t('FINANCEIRO_CONTAS_RECEBER.BLOQUEAR_PORTAL') }}
      </b-button>
    </b-col>
    <b-col md="auto" v-if="exibirBotoesRelatorios">
      <b-button
        variant="info"
        class="botao-acao-filtro mb-3 w-100"
        @click="reimpressao"
        :disabled="disabled.reimpressao"
      >
        {{ $t('FINANCEIRO.REIMPRESSAO') }}
      </b-button>
    </b-col>
    <b-col md="auto" v-if="exibirBotoesRelatorios">
      <b-button
        variant="info"
        class="botao-acao-filtro mb-3 w-100"
        @click="gerarExtrato"
      >
        {{ $t('FINANCEIRO_CONTAS_RECEBER.GERAR_EXTRATO') }}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
// Utils & Aux:
import mensagem from '@/common/utils/mensagem';

// Services:
import ContaReceberService from '@/common/services/conta-receber/contaReceber.service.js';
import ContaService from '@/common/services/conta/conta.service';
import ContratosService from '@/common/services/contratos/contratos.service.js';
import MatriculaService from '@/common/services/matriculas/matriculas.service.js';

// Components:
// import FiltroMatriculaNomeContrato from '@/components/filtro/MatriculaNomeContrato';
import {
  InputSelectMultiple,
  InputCheckbox,
  InputText,
  InputSelectTyping,
} from '@/components/inputs';
import {
  DataLancamento,
  DataPagamento,
  DataVencimento,
} from './opcoes-avancadas/index';

export default {
  props: {
    exibirBotoesRelatorios: { type: Boolean, default: true },
    matriculasIguais: { type: Boolean, default: false },
    disabled: { type: Object, default: Object },
    filtroAntigo: { type: Object, default: () => {} },
  },
  components: {
    // FiltroMatriculaNomeContrato,
    InputCheckbox,
    InputText,
    DataLancamento,
    DataVencimento,
    DataPagamento,
    InputSelectMultiple,
    InputSelectTyping,
  },
  data() {
    return {
      limpar: false,
      loadingConta: false,
      loadingStatus: false,
      loadingHistorico: false,
      loadingMatricula: false,
      loadingContrato: false,
      selecionados: {
        matriculaId: null,
        contratoId: null,
      },
      opcoes: {
        contas: [],
        historicos: [],
        statusPagamento: [],
        contratos: [],
        matriculas: [],
      },
      desabilitar: {
        contrato: true,
      },
      opcoesAvancadas: {
        dataLancamento: false,
        dataPagamento: false,
        dataVencimento: false,
      },
      exibirBuscaAvancada: false,
      filtroRepasse: [
        'codigo',
        'fornecedorId',
        'documento',
        'statusPagamentoId',
        'contaId',
        'historicoLancamentoId',
        'matriculaId',
        'contratoId',
        'dataPagamentoAte',
        'dataPagamentoInicio',
        'dataLancamentoAte',
        'dataLancamentoInicio',
        'dataVencimentoAte',
        'dataVencimentoInicio',
      ],
      filtroAvancadoRepasse: [
        'dataPagamentoAte',
        'dataPagamentoInicio',
        'dataLancamentoAte',
        'dataLancamentoInicio',
        'dataVencimentoAte',
        'dataVencimentoInicio',
      ],
    };
  },
  computed: {
    BtnBaixarTitulo() {
      return this.matriculasIguais ? this.disabled.baixarTitulo : true;
    },
    placeholderContrato() {
      return this.desabilitar.contrato
        ? 'GERAL.SELECIONE_MATRICULA_PARA_HABILITAR'
        : this.opcoes.contratos.length
        ? 'GERAL.DIGITE_OU_PROCURE'
        : 'GERAL.SEM_CONTRATOS_CADASTRADOS';
    },
  },
  watch: {
    exibirBuscaAvancada: {
      handler() {
        this.filtrosAvancados();
      },
    },
    'filtroAntigo.codigo'() {
      if (this.filtroAntigo?.codigo) {
        this.selecionados.codigo = this.filtroAntigo?.codigo;
      }
    },
    'filtroAntigo.matriculaId'() {
      if (this.filtroAntigo?.matriculaId) {
        this.buscaMatriculaFiltroAntigo(this.filtroAntigo?.matriculaId);
      }
    },
    'selecionados.matriculaId'() {
      this.selecionados.contratoId = null;
      if (this.selecionados.matriculaId) {
        this.getContratos();
      } else {
        this.opcoes.contratos = [];
        this.desabilitar.contrato = true;
      }
    },
  },
  mounted() {
    this.getContas();
    this.getStatusPagamento();
    this.getHistoricoContaReceber();
  },
  methods: {
    buscaMatriculaFiltroAntigo(id) {
      this.loadingMatricula = true;
      MatriculaService.buscarPorId(id)
        .then(({ data }) => {
          this.selecionados.matriculaId = data.id;
          this.opcoes.matriculas = [data];
          this.opcoes.matriculas[0].text = `${data.codigoMatricula} - ${data.nome}`;
          this.opcoes.matriculas[0].value = data.id;
          // this.getContratos();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingMatricula = false;
        });
    },
    // validaFiltroAntigoMatricula() {
    //   if (this.filtroAntigo?.matriculaId) {
    //     this.opcoes.matriculas.forEach((item) => {
    //       if (item.value == this.filtroAntigo.matriculaId) {
    //         this.selecionados.matriculaId = item;
    //         this.getContratos();
    //       }
    //     });
    //   }
    // },
    buscaMatriculasPorNome(nome) {
      this.loadingMatricula = true;
      MatriculaService.buscarTodosPorNomeCodigo(nome)
        .then((data) => {
          if (data.itens.length) {
            // data = data.map((row) => {
            //   return {
            //     text: `${row.codigoMatricula} - ${row.nome}`,
            //     value: row.id,
            //   };
            // });
            this.opcoes.matriculas = data.itens;

            // this.validaFiltroAntigoMatricula();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingMatricula = false;
        });
    },
    validaFiltroAntigoContrato() {
      if (this.filtroAntigo?.contratoId) {
        this.opcoes.contratos.forEach((item) => {
          if (item.value == this.filtroAntigo.contratoId) {
            this.selecionados.contratoId = item;
          }
        });
      }
    },
    getContratos() {
      this.selecionados.contratoId = {};
      this.loadingContrato = true;
      ContratosService.buscarContratoPorMatriculaId(
        this.selecionados.matriculaId
      )
        .then(({ data }) => {
          data = data.itens.map((row) => {
            return {
              text: `${row.codigoContrato} - ${row.tipoContratoDescricao} - ${row.grupoTurmaDescricao}`,
              value: row.id,
            };
          });
          this.opcoes.contratos = data;
          this.desabilitar.contrato = false;

          this.validaFiltroAntigoContrato();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingContrato = false;
        });
    },
    validaFiltroAntigoConta() {
      if (this.filtroAntigo?.contaId) {
        this.opcoes.contas.forEach((item) => {
          if (item.value == this.filtroAntigo.contaId) {
            this.selecionados.contaId = item;
          }
        });
      }
    },
    getContas() {
      this.loadingConta = true;
      ContaService.obterTodasSemPaginacao()
        .then(({ data }) => {
          this.opcoes.contas = data;

          this.validaFiltroAntigoConta();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingConta = false;
        });
    },
    validaFiltroAntigoStatus() {
      if (this.filtroAntigo?.statusPagamentoId) {
        this.opcoes.statusPagamento.forEach((item) => {
          if (item.value == this.filtroAntigo.statusPagamentoId) {
            this.selecionados.statusPagamentoId = item;
          }
        });
      }
    },
    getStatusPagamento() {
      this.loadingStatus = true;
      ContaReceberService.buscarStatusPagamento()
        .then((data) => {
          data = data.map((row) => ({
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.statusPagamento = data;

          this.validaFiltroAntigoStatus();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingStatus = false;
        });
    },
    validaFiltroAntigoHistorico() {
      if (this.filtroAntigo?.historicoLancamentoId) {
        this.opcoes.historicos.forEach((item) => {
          if (item.value == this.filtroAntigo.historicoLancamentoId) {
            this.selecionados.historicoLancamentoId = item;
          }
        });
      }
    },
    getHistoricoContaReceber() {
      this.loadingHistorico = true;
      ContaReceberService.buscarHistoricoContaReceber()
        .then((data) => {
          data = data.map((row) => ({
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.historicos = data;

          this.validaFiltroAntigoHistorico();
        })
        .catch((err) => {
          mensagem.erro(this.$t('GERAL.ERRO'), err.response.data.errors);
        })
        .finally(() => {
          this.loadingHistorico = false;
        });
    },
    criarObjetoParaFiltro() {
      return this.filtroRepasse.reduce((acc, string) => {
        acc[string] = this.selecionados[string]?.value;
        return acc;
      }, {});
    },
    criarObjetoParaDeFiltroAvancado() {
      return this.filtroAvancadoRepasse.reduce((acc, string) => {
        acc[string] = this.selecionados[string];
        return acc;
      }, {});
    },
    filtrarPor() {
      let filtro = this.criarObjetoParaFiltro();
      let filtroAvancado = this.criarObjetoParaDeFiltroAvancado();

      let filtros = {
        codigo: this.selecionados.codigo,
        matriculaId: this.selecionados.matriculaId,
        ...filtro,
        ...filtroAvancado,
      };

      filtros.codigo = this.selecionados?.codigo;
      filtros.matriculaId = this.selecionados?.matriculaId;

      this.$emit('resultado-filtro', filtros);
    },

    filtrosAvancados() {
      this.opcoesAvancadas = {
        dataLancamento: false,
        dataPagamento: false,
        dataVencimento: false,
      };
    },

    limparFiltro() {
      this.selecionados = {};
      this.limpar = !this.limpar;
      this.filtrosAvancados();
      this.$emit('limpar');
    },

    // RELATORIOS
    gerarBoleto() {
      this.$emit('gerar-boletos');
    },
    baixarTitulo() {
      this.$emit('baixar-titulo');
    },
    cancelarTitulo() {
      this.$emit('cancelar-conta');
    },
    estornar() {
      this.$emit('estornar');
    },
    portalLiberar() {
      this.$emit('portal-liberar');
    },
    portalBloquear() {
      this.$emit('portal-bloquear');
    },
    reimpressao() {
      this.$emit('reimpressao');
    },
    gerarExtrato() {
      this.$emit('gerar-extrato');
    },
  },
};
</script>
