<template>
  <b-col md="4">
    <b-row>
      <b-col md="12">
        <slot name="checkbox"> </slot>
        <hr />
      </b-col>
      <b-col md="auto">
        <input-date-only-btn
          ref="dataPagamentoInicio"
          v-model="form.dataPagamentoInicio"
          label="FINANCEIRO_CONTAS_RECEBER.DATA_INICIAL"
          placeholder="FINANCEIRO_CONTAS_RECEBER.DATA_PLACEHOLDER"
          :disabled="!exibir"
        />
      </b-col>
      <b-col md="auto">
        <input-date-only-btn
          ref="dataPagamentoLimiteAte"
          v-model="form.dataPagamentoLimiteAte"
          label="FINANCEIRO_CONTAS_RECEBER.DATA_FINAL"
          placeholder="FINANCEIRO_CONTAS_RECEBER.DATA_PLACEHOLDER"
          :disabled="!exibir"
        />
      </b-col>
    </b-row>
  </b-col>
</template>
<script>
// Components:
import { InputDateOnlyBtn } from '@/components/inputs';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: { InputDateOnlyBtn },
};
</script>
