<template>
  <modal
    id="modalNovoLancamento"
    titulo="GERAL.CADASTRAR"
    :exibir="exibir"
    :campos="$refs"
    tamanho="xl"
    @fechar="fecharModal"
  >
    <form-lancamento :form="form" @refs="getRefs" />

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import ContaReceberService from '@/common/services/conta-receber/contaReceber.service.js';

// Components:
import Modal from '@/components/modal/Modal';
import FormLancamento from './Form';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    // form: { type: Object, default: Object },
  },
  components: {
    Modal,
    FormLancamento,
  },
  data() {
    return {
      refs: [],
      form: {},
    };
  },
  methods: {
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    validarDatas() {
      return this.form.dataLancamento > this.form.dataVencimento ? false : true;
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.limparForm();
      this.$emit('fechar', 'novoLancamento');
    },
    limparForm() {
      setTimeout(() => {
        this.form = {};
      }, 200);
    },
    salvar() {
      if (!this.validarDatas())
        return mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t(
            'FINANCEIRO_CONTAS_RECEBER.ERRO_DATA_LANCAMENTO_MAIOR_QUE_VENCIMENTO'
          )
        );
      if (!this.validarFormulario()) return;

      this.$store.dispatch(START_LOADING);
      ContaReceberService.criar(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO_CONTAS_RECEBER.MENSAGEM_REGISTRO')
          );

          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
