import ApiService from '@/common/api/api.service';

const apiBasePath = 'BoletoPix';
const BoletoPixService = {
  async gerarBoletosDeTitulosIds(matriculaId, titulosIds) {
    const {data}  = await ApiService.post(`${apiBasePath}/emitir`, {
      matriculaId,
      titulosIds,
    });
    return data;
  },
};

export default BoletoPixService;
