<template>
  <div>
    <titulo-lancamentos-contas-receber @cadastrar="openModal" />

    <filtro-contas-receber
      :filtroAntigo="filtroAntigo"
      @resultado-filtro="filtrarPor"
      @limpar="limparFiltro"
      :matriculasIguais="itensSelecionadosMatriculasIguais"
      :disabled="disabled"
      @gerar-extrato="imprimirExtrato"
      @baixar-titulo="redirecionarBaixarTitulo"
      @gerar-boletos="gerarBoletos"
      @cancelar-conta="cancelarConta"
      @estornar="estornar"
      @portal-liberar="liberarPortal"
      @portal-bloquear="bloquearPortal"
      @selecionar-todos="selecionarTodos"
      @reimpressao="reimpressao"
    />
    <tabela-contas-receber
      ref="tabelaContasReceber"
      :items="dadosTabela.items"
      :selecionarTodos="acoes.selecionarTodos"
      @itens-selecionados-tabela="obtemSelecionadosTabela"
      :filtrarPor="filtro.buscarPor"
    />

    <paginacao
      :total="totalFiltro"
      v-model="paginacao"
      @input="atualizaTabela"
    />

    <modal-novo-lancamento
      :exibir="modais.novoLancamento"
      @fechar="closeModal"
    />

    <modal-cancelar-conta
      :exibir="modais.cancelarConta"
      :itensSelecionados="this.itensSelecionados"
      @atualizar-tabela="atualizaTabela"
      @limpa-itens-selecionados="limpaItensSelecionadosTabela"
      @fechar="closeModal"
    />
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import rotas from '@/common/utils/rotas';
import modais from '@/common/utils/modais';
import pdf from '@/common/utils/pdf';

// Services:
import ContaReceberService from '@/common/services/conta-receber/contaReceber.service';
import BoletoPixService from '@/common/services/boleto-pix/boleto-pix.service.js';

// Components:
import FiltroContasReceber from './components/filtro/Index';
import TabelaContasReceber from './components/Tabela';
import ModalCancelarConta from './components/modais/ModalCancelarConta';
import ModalNovoLancamento from './components/modais/novo-lancamento/Index';
import Paginacao from '@/components/paginacao/Paginacao';
import TituloLancamentosContasReceber from '@/views/financeiro/lancamentos/contas-receber/components/TituloLancamentosContasReceber.vue';
export default {
  components: {
    TituloLancamentosContasReceber,
    FiltroContasReceber,
    TabelaContasReceber,
    Paginacao,
    ModalCancelarConta,
    ModalNovoLancamento,
  },
  data() {
    return {
      acoes: {
        selecionarTodos: false,
      },
      itensSelecionadosMatriculasIguais: false,
      itensSelecionados: [],
      filtroAntigo: {},
      filtro: {
        resultado: {
          items: [],
          totalFiltro: 0,
        },
        buscarPor: [],
      },
      disabled: {
        gerarBoleto: true,
        baixarTitulo: true,
        cancelar: true,
        fazerEstorno: true,
        liberarPortal: true,
        bloquearPortal: true,
      },
      validacaoMsg: {
        estorno: '',
        baixarTitulo: '',
        geracaoBoleto: '',
      },
      modais: {
        cancelarConta: false,
        novoLancamento: false,
      },
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
      },
    };
  },
  computed: {
    dadosTabela() {
      return this.filtro.resultado;
    },
    totalFiltro() {
      return this.filtro.resultado.totalFiltro;
    },
  },
  mounted() {
    if (localStorage.getItem('filtroContasReceber')) {
      this.filtrarPor(JSON.parse(localStorage.getItem('filtroContasReceber')));
      this.filtroAntigo = JSON.parse(
        localStorage.getItem('filtroContasReceber')
      );
    }
  },
  watch: {
    itensSelecionados() {
      Object.keys(this.disabled).forEach((item) => {
        this.disabled[item] = this.itensSelecionados.length ? false : true;
      });
    },
  },
  methods: {
    filtrarPor(filtroUtilizado) {
      this.paginacao.numeroDaPagina = 1;
      this.filtro.buscarPor = filtroUtilizado;
      this.atualizaTabela();
    },
    atualizaTabela() {
      if (!this.validadorGeralDatas()) return;
      this.itensSelecionados = [];
      this.$store.dispatch(START_LOADING);
      ContaReceberService.buscarContasReceber(
        this.paginacao,
        this.filtro.buscarPor
      )
        .then(({ data }) => {
          this.filtro.resultado.items = data.itens;
          this.filtro.resultado.totalFiltro = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
      return;
    },
    limpaItensSelecionadosTabela() {
      this.$refs['tabelaContasReceber'].limparItensArmazenados();
    },
    redirecionarBaixarTitulo() {
      if (!this.validaBaixaTitulo())
        return mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.validacaoMsg.baixarTitulo
        );

      let idsLancamento = this.itensSelecionados.map(({ id }) => id);

      this.$store.commit('alterarDadosLancamento', {
        idsLancamento,
        lancamentoLista: idsLancamento.length > 1 ? true : false,
      });

      idsLancamento.length > 1
        ? this.irPara('financeiro-contas-receber-baixar-titulos')
        : this.irPara('financeiro-contas-receber-baixar-titulo');

      localStorage.setItem(
        'filtroContasReceber',
        JSON.stringify(this.filtro.buscarPor)
      );
    },

    selecionarTodos(opcao) {
      this.acoes.selecionarTodos = opcao;
    },

    // FUNÇÕES IMPRESSÃO:
    reimpressao() {
      let ids = this.itensSelecionados.map(({ id }) => id);
      this.imprimir({ ids: ids });
    },

    validaReimpressao() {
      const valorParaChecagem = this.itensSelecionados[0].codigoMatricula;

      const resultadoChecagem = this.itensSelecionados.some(
        (x) => x.codigoMatricula !== valorParaChecagem
      );

      if (resultadoChecagem) {
        this.validacaoMsg.baixarTitulo =
          'Não é permitido realizar a reimpressão de uma lista de recibos pertencentes a diferentes matrículas.';
        return false;
      }

      return true;
    },

    imprimir(id) {
      if (!this.validaReimpressao())
        return mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.validacaoMsg.baixarTitulo
        );

      this.$store.dispatch(START_LOADING);
      ContaReceberService.imprimirRecibo(id)
        .then(({ data }) => {
          if (data.documento) {
            //converte os bytes em array:
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            // converte o array em blob
            let blob = new Blob([bytes], { type: 'application/pdf' });

            // faz download do PDF:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FUNÇÕES GERAR BOLETO
    validaGeracaoBoleto() {
      const valorParaChecagem = this.itensSelecionados[0].codigoMatricula;
      const resultadoChecagem = this.itensSelecionados.some(
        (x) => x.codigoMatricula !== valorParaChecagem
      );
      if (resultadoChecagem) {
        this.validacaoMsg.geracaoBoleto =
          'Não é permitido gerar boletos de uma lista de títulos pertencentes a diferentes matrículas.';
        return false;
      }
      return true;
    },

    gerarBoletos() {
      if (!this.validaGeracaoBoleto())
        return mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.validacaoMsg.geracaoBoleto
        );
      if (!this.checarStatusAbertoOuLiquidadoParcialmente())
        return mensagem.erro(
          this.$t('GERAL.ERRO'),
          'Só é possível gerar boletos para títulos abertos ou parcialmente liquidados'
        );
      this.$store.dispatch(START_LOADING);
      const matriculaId = this.itensSelecionados[0].matriculaId;
      const titulosIds = this.itensSelecionados.map((t) => t.id);
      BoletoPixService.gerarBoletosDeTitulosIds(matriculaId, titulosIds)
        .then(({ data }) => {
          data.arquivo.forEach((a) => {
            //converte os bytes em array:
            let bytes = pdf.base64ToArrayBuffer(a.item2);
            // converte o array em blob
            let blob = new Blob([bytes], { type: 'application/pdf' });
            // faz download do PDF:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = a.item1;
            link.click();
          });
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FUNÇÕES TABELA:
    obtemSelecionadosTabela(items, allEqual) {
      this.itensSelecionadosMatriculasIguais = allEqual;
      this.itensSelecionados = items;
    },

    // FUNÇÕES PORTAL:
    liberarPortal() {
      let idsLiberados = this.itensSelecionados.map(({ id }) => id);
      let lista = { ids: idsLiberados };
      this.$store.dispatch(START_LOADING);
      ContaReceberService.liberarPortal(lista)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            'Liberado no portal do aluno com sucesso!'
          );
          this.atualizaTabela();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
      return;
    },

    bloquearPortal() {
      let idsLiberados = this.itensSelecionados.map(({ id }) => id);
      let lista = { ids: idsLiberados };
      this.$store.dispatch(START_LOADING);
      ContaReceberService.bloquearPortal(lista)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            'Bloqueado no portal do aluno com sucesso!'
          );
          this.atualizaTabela();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
      return;
    },

    // FUNÇÕES DE VALIDAÇÃO
    validarDatas(dataLimite, data, msg) {
      if (
        (data !== '' && dataLimite === '') ||
        (data === '' && dataLimite !== '')
      ) {
        mensagem.warning(
          this.$t('GERAL.AVISO'),
          this.$t(
            `A data inicial e data limite de '${msg}' devem ser preenchidas`
          )
        );
        return true;
      } else if (dataLimite < data) {
        mensagem.warning(
          this.$t('GERAL.AVISO'),
          this.$t(
            `O limite da data de '${msg}' deve ser maior ou igual a data inicial`
          )
        );
        return true;
      }
      return false;
    },

    validadorGeralDatas() {
      const validarLista = [];
      return validarLista.every((item) => item === false);
    },

    checarStatusAbertoOuLiquidadoParcialmente() {
      for (let index = 0; index < this.itensSelecionados.length; index++) {
        const tituloAtual = this.itensSelecionados[index];
        if (
          tituloAtual.statusPagamentoId === 142 ||
          tituloAtual.statusPagamentoId === 299
        )
          continue;
        return false;
      }
      return true;
    },

    checaStatusLiquidadoAberto() {
      let liquidado = false;
      for (let index = 0; index < this.itensSelecionados.length; index++) {
        const element = this.itensSelecionados[index];

        if (
          element.statusPagamentoId === 301 ||
          element.statusPagamentoId === 299
        ) {
          liquidado = true;
        } else {
          return (liquidado = false);
        }
      }
      return liquidado;
    },

    validaBaixaTitulo() {
      /* Regras para estorno:
				 1. Selecionar pelo menos um lançamento
				 2. Somente títulos abertos ou estornados podem sofrer baixa
				 3. Obrigatoriamente precisam ser do mesmo aluno
			*/

      // limpa msg inicial:
      this.validacaoMsg.baixarTitulo = '';

      // Regra 01:
      if (!this.itensSelecionados.length) {
        this.validacaoMsg.baixarTitulo = 'Selecione algum lançamento da lista';
        return false;
      }
      // Regra 02:
      if (!this.checaStatusLiquidadoAberto()) {
        this.validacaoMsg.baixarTitulo =
          'Somente títulos abertos ou estornados podem sofrer baixa';
        return false;
      }
      return true;
    },

    validaEstorno() {
      /* Regras para estorno:
				 1. Somente um título por vez
				 2. Estorna somente títulos liquidados
				 3. Pode ser de alunos diferentes
			*/

      // limpa msg inicial:
      this.validacaoMsg.estorno = '';
      // Regra 01:
      if (this.itensSelecionados.length != 1) {
        this.validacaoMsg.estorno = 'Selecione um título por vez.';
        return false;
      }

      return true;
    },

    // FUNÇÕES CRUD:

    estornar() {
      if (!this.validaEstorno())
        return mensagem.erro(this.$t('GERAL.ERRO'), this.validacaoMsg.estorno);
      this.$store.dispatch(START_LOADING);

      ContaReceberService.estornar(this.itensSelecionados[0]?.id)
        .then(() => {
          this.atualizaTabela();
          this.limpaItensSelecionadosTabela();
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO_CONTAS_RECEBER.MENSAGEM_ESTORNO')
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    cancelarConta() {
      const checarStatusCancelado = this.itensSelecionados.find(
        (x) =>
          x.statusPagamentoId === 302 ||
          x.statusPagamentoId === 300 ||
          x.statusPagamentoId === 142
      );

      if (checarStatusCancelado !== undefined) {
        mensagem.warning(
          this.$t('GERAL.AVISO'),
          this.$t('FINANCEIRO_CONTAS_RECEBER.CANCELAMENTO_NAO_PERMITIDO')
        );
        return;
      }

      this.openModal('cancelarConta');
    },

    // RELATORIOS
    imprimirExtrato() {
      if (!this.validadorGeralDatas()) return;
      this.$store.dispatch(START_LOADING);
      ContaReceberService.gerarExtrato(this.filtro.buscarPor)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], { type: 'application/pdf' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FILTRO
    limparFiltro() {
      this.itensSelecionados = [];
      this.filtro.resultado.items = [];
      this.filtro.resultado.totalFiltro = 0;
    },

    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
