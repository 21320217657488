<template>
  <div class="row">
    <!-- buscando matrícula e contrato -->
    <div class="col-12">
      <filtro-matricula-nome-contrato @resultado="obterMatriculaNomeContrato" />
    </div>

    <!-- demais dados -->
    <div class="col-12 col-md-4 mt-2">
      <!-- TODO: mask preço -->
      <input-text
        ref="valor"
        :label="$t(`PRECOS.VALOR`)"
        type="number"
        min="0"
        v-model="form.valor"
        required
      />
    </div>
    <!-- datas -->
    <div class="col-12 col-md-4 mt-2">
      <input-date-only-btn
        ref="dataLancamento"
        label="FINANCEIRO_CONTAS_RECEBER.DATA_LANCAMENTO"
        v-model="form.dataLancamento"
        required
      />
    </div>
    <div class="col-12 col-md-4 mt-2">
      <input-date-only-btn
        ref="dataVencimento"
        v-model="form.dataVencimento"
        label="FINANCEIRO_CONTAS_RECEBER.DATA_VENCIMENTO"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select
        ref="conta"
        v-model="form.contaId"
        label="FINANCEIRO_CONTAS_RECEBER.CONTA"
        :options="opcoes.contas"
        valueField="id"
        textField="descricao"
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select
        ref="historico"
        v-model="form.historicoLancamentoId"
        :options="opcoes.historicos"
        label="FINANCEIRO_CONTAS_RECEBER.HISTORICO"
        valueField="id"
        textField="descricao"
        required
      />
    </div>
    <div class="col-12">
      <input-text-area
        ref="observacao"
        v-model="form.observacao"
        label="FINANCEIRO_CONTAS_RECEBER.OBSERVACOES"
      />
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import ContaReceberService from '@/common/services/conta-receber/contaReceber.service.js';

// Components:
import {
  InputSelect,
  InputText,
  InputDateOnlyBtn,
  InputTextArea,
} from '@/components/inputs';
import FiltroMatriculaNomeContrato from '@/components/filtro/MatriculaNomeContrato';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    FiltroMatriculaNomeContrato,
    InputText,
    InputDateOnlyBtn,
    InputSelect,
    InputTextArea,
  },
  data() {
    return {
      opcoes: {
        contas: [],
        historicos: [],
      },
    };
  },
  mounted() {
    this.$emit('refs', this.$refs);
    this.getContas();
    this.getHistoricoContaReceber();
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    obterMatriculaNomeContrato(selecionado) {
      this.form.matriculaId = selecionado.matriculaId?.value;
      this.form.contratoId = selecionado.contratoId?.value;
    },
    getHistoricoContaReceber() {
      ContaReceberService.buscarHistoricoContaReceber()
        .then((data) => {
          this.opcoes.historicos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getContas() {
      this.$store.dispatch(START_LOADING);
      ContaReceberService.buscarConta()
        .then((data) => {
          this.opcoes.contas = data.itens;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
