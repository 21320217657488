<template>
  <modal
    id="modalCancelar"
    :exibir="exibir"
    titulo="FINANCEIRO_CONTAS_RECEBER.CANCELAR_TITULO"
    :campos="$refs"
    @fechar="fecharModal"
  >
    <div class="row">
      <div class="col-12">
        <input-text
          ref="cancelamentoObservacao"
          v-model="cancelamentoObservacao"
          :label="$t(`FINANCEIRO_CONTAS_RECEBER.OBSERVACAO_CANCELAMENTO`)"
        />
      </div>
    </div>

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button class="ml-2" variant="primary" @click="cancelarTitulo">
          {{ $t('FINANCEIRO.CANCELAR_TITULO') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import ContaReceberService from '@/common/services/conta-receber/contaReceber.service';

// Components:
import Modal from '@/components/modal/Modal.vue';
import { InputText } from '@/components/inputs';

export default {
  components: {
    Modal,
    InputText,
  },
  props: {
    exibir: { type: Boolean, default: false },
    itensSelecionados: { type: Array, default: Array },
  },
  data() {
    return {
      cancelamentoObservacao: '',
    };
  },
  watch: {
    exibir() {
      this.cancelamentoObservacao = '';
    },
  },
  methods: {
    cancelarTitulo() {
      const titulosCancelados = this.itensSelecionados.map((item) => ({
        ...item,
        cancelamentoObservacao: this.cancelamentoObservacao,
      }));

      this.$store.dispatch(START_LOADING);
      ContaReceberService.cancelarTitulo(titulosCancelados)
        .then(({ data }) => {
          this.$emit('atualizar-tabela', data);
          this.$emit('limpa-itens-selecionados');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.fecharModal();
        });
    },
    fecharModal() {
      this.$emit('fechar', 'cancelarConta');
    },
  },
};
</script>
